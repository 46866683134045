import React from "react"
import { Helmet } from "react-helmet"

import HomePage from "../containers/HomePage"
import "./index.scss"

export default () => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="author" content="Gjermund Norderhaug" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>
        Gjermund Norderhaug - Developing pretty stuff in your favorite browser
      </title>
      <meta
        name="description"
        content="Frontend developer focused on good user experience, pretty design and solid code."
      />
    </Helmet>
    <HomePage />
  </div>
)
