import React from "react"

import "./sectiondivider.scss"

class SectionDivider extends React.Component {
  // eslint-disable-line react/prefer-stateless-function
  render() {
    return <div className="section-divider-wrapper">|</div>
  }
}

export default SectionDivider
